















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PollAnswer from '@/models/graphql/PollAnswer';
import Session from '@/models/graphql/Session';
import KeyValue from '@/models/graphql/KeyValue';
import Variant from '@/utils/enums/Variant';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import PillComponent from '@/components/pill/PillComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import {
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PollItemMenuComponent from '@/components/poll/PollItemMenuComponent.vue';
import { Portal } from 'portal-vue';
import { Getter, State } from 'vuex-class';
import PollUserAnswerVersion from '@/models/graphql/PollUserAnswerVersion';
import Community from '@/models/graphql/Community';

/* eslint-disable no-underscore-dangle */

@Component({
  components: {
    PollItemMenuComponent,
    ButtonComponent,
    FontAwesomeComponent,
    PillComponent,
    Portal,
  },
})
export default class PollItemComponent extends Vue {
  @Prop({ required: true })
  private readonly uid!: string;

  @Prop({ required: true })
  private readonly title!: string;

  @Prop({
    required: true,
    default: () => [],
  })
  private pollAnswers!: Array<PollAnswer>;

  @Prop({
    required: true,
    default: () => [],
  })
  private versions!: Array<PollUserAnswerVersion>;

  @Prop({
    required: true,
    default: null,
  })
  private session!: Session;

  @Prop({
    required: false,
    default: false,
  })
  private isSelected!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private realTimeAnswers!: boolean | null;

  @Prop({
    required: false,
    default: null,
  })
  private startTime!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private endTime!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  private startTimestamp!: number | null;

  @Prop({
    required: false,
    default: null,
  })
  private endTimestamp!: number | null;

  @Prop({
    required: false,
    default: () => [],
  })
  private answerCountByAnswerId!: Array<KeyValue>;

  @Prop({
    required: false,
    default: () => [],
  })
  private _myAnswer!: PollUserAnswer[];

  @Prop({
    required: false,
    default: false,
  })
  private hideActions!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private canCreate!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isModerator!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly teleportMenu!: string | null;

  @State
  private selectedTzName!: string;

  @Getter
  private community!: Community;

  private Variant = Variant;

  private setIntervalId: NodeJS.Timer | null = null;

  private now = DateTimeHelper.nowInZonedTime(this.selectedTzName);

  private get isPollClosed(): boolean {
    if (!this.endTime && this.startTime) {
      return false;
    }
    const endTime = this.endTime
      ? addSeconds(DateTimeHelper.utcToZonedTimeDate(
        `${this.endTime}Z`,
        this.selectedTzName,
      ), this.community.votingBufferTime ?? 0)
      : null;
    if (endTime) {
      return this.now > endTime;
    }
    return true;
  }

  private get totalCount(): string {
    let count = 0;
    if (this.pollAnswers && this.pollAnswers.length) {
      count = this.pollAnswers.reduce((acc, item) => acc + (item.answerCount ? item.answerCount : 0), 0);
    }
    return `${this.$tc('polls.total-vote', 0, { voteCount: count })}`;
  }

  private get timeLeft(): string {
    let result = '';
    if (this.isPollClosed) {
      result = `${this.$t('polls.voting-closed')}`;
    } else if (this.endTime) {
      const localEndTime = addSeconds(DateTimeHelper.utcToZonedTimeDate(
        `${this.endTime}Z`,
        this.selectedTzName,
      ), this.community.votingBufferTime ?? 0);
      const today = this.now;
      if (localEndTime >= today) {
        let daysDifference = differenceInDays(today, localEndTime);
        daysDifference = daysDifference < 0 ? -daysDifference : daysDifference;
        let hoursDifference = differenceInHours(today, localEndTime);
        hoursDifference = hoursDifference < 0 ? -hoursDifference : hoursDifference;
        let minutesDifference = differenceInMinutes(today, localEndTime);
        minutesDifference = minutesDifference < 0 ? -minutesDifference : minutesDifference;
        let secondsDifference = differenceInSeconds(today, localEndTime);
        secondsDifference = secondsDifference < 0 ? -secondsDifference : secondsDifference;
        if (daysDifference > 7) {
          result = this.$tc('polls.voting-open');
        } else if (daysDifference >= 1) {
          result = this.$tc('polls.left.days', 0, {
            timeLeftDiff: daysDifference,
          });
        } else if (hoursDifference <= 24 && hoursDifference >= 1) {
          result = this.$tc('polls.left.hours', 0, {
            timeLeftDiff: hoursDifference,
          });
        } else if (minutesDifference >= 1) {
          result = this.$tc('polls.left.minutes', 0, {
            timeLeftDiff: minutesDifference,
          });
        } else {
          result = this.$tc('polls.left.seconds', 0, {
            timeLeftDiff: secondsDifference,
          });
        }
      }
    }
    return result;
  }

  private get pollAnswersTotalCount(): number {
    if (this.pollAnswers.length > 0) {
      return this.pollAnswers.reduce((acc, item) => acc + (item.answerCount ? item.answerCount : 0), 0);
    }
    return 0;
  }

  private created(): void {
    this.setIntervalId = setInterval(() => {
      this.now = DateTimeHelper.nowInZonedTime(this.selectedTzName);
    }, 950);
  }

  private beforeDestroy(): void {
    if (this.setIntervalId) {
      clearInterval(this.setIntervalId);
    }
  }

  private getAnswerPercentage(pollAnswer: PollAnswer): string {
    const pollAnswerCount = pollAnswer.answerCount || 0;
    return `${this.$tc('polls.answer-percent', 0, {
      count: pollAnswerCount > 0
        ? (parseInt(((100 * pollAnswerCount) / this.pollAnswersTotalCount).toString(), 10))
        : 0,
    })
    }`;
  }
}





































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import {
  format, isAfter, isBefore, isWithinInterval,
} from 'date-fns';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResource from '@/models/graphql/FileResource';
import Exhibitor from '@/models/graphql/Exhibitor';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import PillWidget from '@/components/pill/PillWidget.vue';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import PillCategoriesComponent from '@/components/pill/PillCategoriesComponent.vue';
import Category from '@/models/graphql/Category';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { mixins } from 'vue-class-component';
import SpeakerRole from '@/models/graphql/SpeakerRole';
import ActionRule from '@/utils/enums/ActionRule';
import ActionRuleService from '@/services/ActionRuleService';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Session from '@/models/graphql/Session';
import Size from '@/utils/enums/Size';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';
import Geozone from '@/models/graphql/Geozone';
import { runMathJax } from '@/utils/helpers/LatexHelper';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');
const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    RecommendationBadgeComponent,
    PillCategoriesComponent,
    FontAwesomeComponent,
    PillWidget,
    AvatarSoloWidget,
    ButtonIconComponent,
  },
  inheritAttrs: false,
})
/* eslint-disable max-len */
export default class SessionCardWidget extends mixins(BreakpointWrapper, BaseCardWidget) {
  @Prop({
    required: true,
    default: '',
  })
  readonly uid!: string;

  @Prop({
    required: true,
    default: -1,
  })
  readonly id!: number;

  @Prop({
    required: true,
    default: '',
  })
  readonly name!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly bannerFileResource!: FileResource;

  @Prop({
    required: false,
    default: false,
  })
  readonly featured!: boolean;

  @Prop({
    required: true,
    default: '',
  })
  readonly startTime!: string;

  @Prop({
    required: true,
    default: '',
  })
  readonly endTime!: string;

  @Prop({
    required: false,
    default: null,
  })
  readonly _isBookmarked!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  readonly _isInAgenda!: string | null;

  @Prop({
    required: false,
    default: null,
  })
  readonly exhibitor!: Exhibitor;

  @Prop({
    required: false,
    default: null,
  })
  readonly categoriesInContext!: Category[];

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ default: 0 })
  readonly _bookmarkCount!: number;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayRecommendedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayFeaturedBorder!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayLiveBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayInterestedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displaySpeakers!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  readonly speakerRoles!: SpeakerRole[];

  @Prop({
    required: false,
    default: null,
  })
  readonly _actions!: { key: string; value: string }[];

  @Prop({
    required: false,
    default: false,
  })
  readonly horizontalDisplay!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  readonly displayHall!: boolean;

  @communityUserBookmarkStore.Action
  bookmark!: (
    payload: CommunityUserBookmarkFilter,
  ) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (
    payload: CommunityUserBookmarkFilter,
  ) => Promise<CommunityUserBookmark | undefined>;

  @communityUserAgendaEntryStore.Action
  addToAgenda!: (
    payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  removeFromAgenda!: (
    payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @Getter
  community!: Community;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({
    required: false,
    default: null,
  })
  private geozones!: Geozone[];

  @Getter
  private authUser!: CommunityUser;

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  // eslint-disable-next-line no-underscore-dangle
  private bookmarked = this._isBookmarked;

  // eslint-disable-next-line no-underscore-dangle
  private isInAgenda = this._isInAgenda;

  private FeatureKeys = FeatureKeys;

  private FileResourceHelper = FileResourceHelper;

  private sessionDate = '';

  private inlineSpeakers = '';

  private moreSpeakers = '';

  private speakerIndex = 0;

  private ActionRule = ActionRule;

  private ActionRuleService = ActionRuleService;

  private sizeEnum = Size;

  get location(): string | null {
    if (this.geozones && this.geozones.length > 0) {
      const geozone = this.geozones[0];
      if (this.displayHall && geozone.exhibitHall && geozone.exhibitHall.name) {
        if (geozone.name) {
          return `${geozone.exhibitHall.name} · ${geozone.name}`;
        }
        return geozone.exhibitHall.name;
      }
      if (geozone && geozone.name) {
        return geozone.name;
      }
    }
    return null;
  }

  private get noImage(): boolean {
    if (this.bannerFileResource) {
      return false;
    }
    return !this.community.defaultSessionBannerFileResource;
  }

  private get route(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          r = r.replaceAll(m, this.$props[prop]);
          r = r.replaceAll(m, this.$route.params[prop]);
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get sessionState(): string {
    const today = DateTimeHelper.utcToZonedTimeDate(
      DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    );
    const startTime = DateTimeHelper.utcToZonedTimeDate(
      `${this.startTime}Z`,
      this.selectedTzName,
    );
    const endTime = DateTimeHelper.utcToZonedTimeDate(
      `${this.endTime}Z`,
      this.selectedTzName,
    );
    if (this.startTime
      && this.endTime
      && isBefore(startTime, endTime)
      && isWithinInterval(today, {
        start: startTime,
        end: endTime,
      })) {
      return 'live';
    }
    if (this.endTime && isAfter(today, endTime)) {
      return 'archived';
    }
    return '';
  }

  mounted(): void {
    this.setSessionDate();
  }

  private created(): void {
    this.initSpeakers();
  }

  @Watch('isEventTimeZone')
  private setSessionDate(): void {
    const dateFormat = this.$t('app.date.monthDayShort')
      .toString();
    const timeFormat = this.$t('app.date.defaultTimeFormat')
      .toString();
    const separator = this.$t('app.date.dateTimeSeparator')
      .toString();
    const timeZoneAbbreviation = `${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
    || !(this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE) as CommunityFeature).enabled
      ? `(${this.selectedTzAbbreviation})`
      : ''}`;

    this.sessionDate = '';
    let date = '';
    let time = '';
    if (this.startTime) {
      const startTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.startTime}Z`,
        this.selectedTzName,
      );
      date = `${format(startTime, dateFormat, { locale: this.dateLocale })}`;
      time = `${format(startTime, timeFormat, { locale: this.dateLocale })}`;
    }
    if (this.endTime) {
      const endTime = DateTimeHelper.utcToZonedTimeDate(
        `${this.endTime}Z`,
        this.selectedTzName,
      );
      date = date || `${format(endTime, timeFormat, { locale: this.dateLocale })}`;

      const sep = time ? separator : '';

      time = `${time}${sep}${format(endTime, timeFormat, { locale: this.dateLocale })}`;
    }

    if (date && time) {
      this.sessionDate = `${date}, ${time} ${timeZoneAbbreviation}`;
    }
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.SESSION,
      })
        .then((response) => {
          this.bookmarked = response?.uid || '';
          if (this.bookmarked) {
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.SESSION,
              StatLoggerActions.ADD,
              '',
              this.id,
              this.uid,
              StatLoggerCategories.BOOKMARK,
              this.$i18n.locale,
            );
          }
        })
        .catch(() => {
          this.bookmarked = null;
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private updated(): void {
    runMathJax();
  }

  private toggleAgenda(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isInAgenda) {
      this.removeFromAgenda({
        event: AgendaStoreHelper.convertSessionToEvent(this.$props as Session, this.selectedTzAbbreviation),
        uid: this.isInAgenda,
      });
      this.isInAgenda = null;
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        EntityType.SESSION,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.uid,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
      );
    } else {
      this.addToAgenda({
        event: AgendaStoreHelper.convertSessionToEvent(this.$props as Session, this.selectedTzAbbreviation),
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.SESSION,
      })
        .then((response) => {
          this.isInAgenda = response?.uid || '';
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.SESSION,
            StatLoggerActions.ADD_TO_AGENDA,
            'addAppointment',
            -1,
            this.uid,
            StatLoggerCategories.ADD,
            this.$i18n.locale,
          );
        });
    }
  }

  @Watch('inlineSpeakers')
  @Watch('moreSpeakers')
  private initSpeakers(): void {
    this.$nextTick(() => {
      const element = document.getElementById(`speakers-${this.id}`) as HTMLElement;
      if (this.speakerRoles && this.speakerRoles.length > 0) {
        if (this.speakerRoles.length >= this.speakerIndex) {
          const speakerRole = this.speakerRoles[this.speakerIndex];
          const speakerName = speakerRole ? `${speakerRole.speaker.firstName} ${speakerRole.speaker.lastName}` : '';
          if (element && element.getBoundingClientRect().height < 32 && speakerRole) {
            this.moreSpeakers = this.speakerRoles.length - (this.speakerIndex + 1) > 0
              ? `${this.$tc('component.session-card.more-speakers', 0, { n: this.speakerRoles.length - (this.speakerIndex + 1) })}`
              : '';
            this.inlineSpeakers = this.speakerIndex === 0 ? `${speakerName}` : `${this.inlineSpeakers}, ${speakerName}`;
            this.speakerIndex += 1;
          }
        }
      }
    });
  }
}
